const prefix = 'LMS_';

export const USER_MAGICLINK_REQUESTED = `${prefix}User_MagicLink_Requested`;
export const USER_LOGIN_SUCCESSFUL = `${prefix}User_Login_Successful`;
export const USER_LOGIN_UNSUCCESSFUL = `${prefix}User_Login_Unsuccessful`;
export const USER_LOGOUT = `${prefix}User_Logout`;

export const COURSE_ACCESSED = `${prefix}Course_Accessed`;
export const COURSE_ACCESS_FAILURE = `${prefix}Course_Access_Failure`;
export const COURSE_STARTED = `${prefix}Course_Started`;
export const COURSE_RESUMED = `${prefix}Course_Resumed`;
export const COURSE_RESTARTED = `${prefix}Course_Restarted`;
export const COURSE_COMPLETED = `${prefix}Course_Completed`;
export const COURSE_LANGUAGE_CHANGE = `${prefix}Course_Language_Change`;

export const MODULE_ACCESSED = `${prefix}Module_Accessed`;

export const CONTENT_ACCESSED = `${prefix}Content_Accessed`;
export const CONTENT_COMPLETED = `${prefix}Content_Completed`;
export const CONTENT_ACCESSED_FAILURE = `${prefix}Content_Accessed_Failure`;

export const EXAM_STARTED = `${prefix}Exam_Started`;
export const EXAM_RETAKE = `${prefix}Exam_Retake`;
export const EXAM_COMPLETED = `${prefix}Exam_Completed`;
export const EXAM_PASSED = `${prefix}Exam_Passed`;
export const EXAM_FAILED = `${prefix}Exam_Failed`;
export const SHARE_FEEDBACK_CLICKED = `${prefix}Share_Feedback_Clicked`;
export const SHARE_FEEDBACK_SUBMITTED = `${prefix}Share_Feedback_Subtmitted`;
export const FEEDBACK_PAGE_ACCESSED = `${prefix}Feedback_Page_Accessed`;
export const HOME_PAGE_ACCESSED = `${prefix}Home_Page_Accessed`;
export const HOME_PAGE_ACCESS_FAILURE = `${prefix}Home_Page_Access_Failure`;
export const HOME_PAGE_COURSE_TILE_CLICKED = `${prefix}Home_Page_Course_Tile_Clicked`;
export const COURSE_TILE_CLICKED = `${prefix}Course_Tile_Clicked`;
export const COURSE_LIST_COURSE_TILE_CLICKED = `${prefix}Course_List_Page_Course_Tile_Clicked`;
export const HOME_PAGE_LOCKED_COURSE_TILE_CLICKED = `${prefix}Home_Page_Locked_Course_Tile_Clicked`;
export const HOME_PAGE_SEE_MORE_CLICKED = `${prefix}Home_Page_See_More_Clicked`;

export const QUESTION_SUBMITTED = `${prefix}Question_Submitted`;
export const QUESTION_SUCCESSFULLY_SUBMITTED = `${prefix}Question_Successfully_Submitted`;
export const ANSWER_LIKED = `${prefix}Answer_Liked`;
export const DEEP_LINK_ACCESSED = `${prefix}Deep_Link_Accessed`;
export const ASK_QUESTION_CLICKED = `${prefix}Ask_Question_Clicked`;
export const REGISTER_WEBINAR_CLICKED = `${prefix}Register_Webinar_Clicked`;
export const LOCKED_COURSE_CLICKED = `${prefix}Locked_Course_Clicked`;
export const START_EXAM_CTA_CLICKED = `${prefix}Start_Exam_CTA_Clicked`;
export const PAID_COURSE_CTA_CLICKED = `${prefix}Paid_Course_CTA_Clicked`;

export const BANNER_CLICKED = `${prefix}Banner_Clicked`;
export const QUICK_LINKS_CLICKED = `${prefix}Quick_Links_Clicked`;

export const WEBINAR_LISTING_PAGE_ACCESSED = `${prefix}Webinar_Listing_Page_Accessed`;
export const WEBINAR_LISTING_PAGE_ACCESS_FAILURE = `${prefix}Webinar_Listing_Page_Access_Failure`;
export const WEBINAR_DETAIL_PAGE_ACCESSED = `${prefix}Webinar_Detail_Page_Accessed`;
export const WEBINAR_DETAIL_PAGE_ACCESS_FAILURE = `${prefix}Webinar_Detail_Page_Access_Failure`;
export const WEBINAR_REGISTER_NOW_CLICKED = `${prefix}Webinar_Register_Now_Clicked`;
export const WEBINAR_JOIN_NOW_CLICKED = `${prefix}Webinar_Join_Now_Clicked`;

export const SEARCH_ACCESED = `${prefix}Search_Accessed`;
export const QUERY_SEARCHED = `${prefix}Query_Searched`;
export const SEARCH_RESULT_COURSE_CLICKED = `${prefix}Search_Result_Course_Clicked`;
export const SEARCH_RESULT_WEBINAR_CLICKED = `${prefix}Search_Result_Webinar_Clicked`;
export const NO_SEARCH_RESULT_RETURNED = `${prefix}No_Search_Result_Returned`;
export const SEARCH_RESULT_RETURNED = `${prefix}Search_Result_Returned`;

export const BEGIN_MASTERCLASS_CLICKED = `${prefix}Begin_Masterclass_Clicked`;
export const EXPLORE_MINTACADEMY_CLICKED = `${prefix}Explore_MintAcademy_Clicked`;

export const LMS_CATEGORIES_FETCH_FAILURE = `${prefix}Categories_Fetch_Failure`;

export const LMS_SEND_TIME_FAILURE = `${prefix}Send_Time_Failure`;

export const APESTER_LOADING_FAILURE = `${prefix}Apester_Loading_Failure`;

export const HOME_PAGE_CHANNEL_SEE_MORE_CLICKED = `${prefix}Home_Page_Channel_See_More_Clicked`;
export const CHANNEL_CATEGORY_SEE_MORE_CLICKED = `${prefix}Channel_Category_See_More_Clicked`;
export const HOME_PAGE_CHANNEL_TILE_CLICKED = `${prefix}Home_Page_Channel_Tile_Clicked`;
export const CHANNELS_LIST_CHANNEL_TILE_CLICKED = `${prefix}Channels_List_Channel_Tile_Clicked`;
export const CHANNEL_COURSE_TILE_CLICKED = `${prefix}Channel_Course_Tile_Clicked`;
export const CHANNEL_CONTENT_TILE_CLICKED = `${prefix}Channel_Content_Tile_Clicked`;
export const HOME_PAGE_CONTENT_TILE_CLICKED = `${prefix}Home_Page_Content_Tile_Clicked`;
export const CONTENT_TILE_CLICKED = `${prefix}Content_Tile_Clicked`;
export const CUSTOMER_PITCH_ACCESSED = `${prefix}Customer_Pitch_Accessed`;
export const CUSTOMER_PITCH_STARTED_RECORDING = `${prefix}Customer_Pitch_Started_Recording`;
export const CLIENT_VIDEO_PLAYED = `${prefix}Client_Video_Played`;
export const BACK_BUTTON_CLICKED = `${prefix}Back_Button_Clicked`;
export const PAUSE_RECORDING_CLICKED = `${prefix}Pause_Recording_Clicked`;
export const RESTART_RECORDING = `${prefix}Restart_Recording`;
export const SUBMIT_BUTTON_CLICKED = `${prefix}Submit_Button_Clicked`;
export const FINAL_SUBMIT_BUTTON_CLICKED = `${prefix}Final_Submit_Button_Clicked`;
export const FINAL_DONE_BUTTON_CLICKED_AFTER_SUBMIT = `${prefix}Final_Done_Button_Clicked_After_Submit`;
export const CUSTOMER_PITCH_COMPLETED = `${prefix}Customer_Pitch_Completed`;
export const REVIEW_RECORDING_CLICKED = `${prefix}Review_Recording_Clicked`;

export const START_LEARNING_CLICKED = `${prefix}LP_Start_Learning_CTA_Clicked`;
export const MODULE_DRAWER_OPENED = `${prefix}LP_Module_Drawer_Opened`;
export const MODULE_DRAWER_CLOSED = `${prefix}LP_Module_Drawer_Closed`;
export const DRAWER_CONTENT_SELECTED = `${prefix}LP_Module_Drawer_Content_Selected`;
export const CONTENT_REPLAY_CLICKED = `${prefix}LP_Content_Replay_Clicked`;
export const LP_RESUMED = `${prefix}LP_Resume_Loaded`;
export const LANDING_PAGE_LOADED = `${prefix}LP_LandingPage_Loaded`;
export const LP_MODULE_STARTED = `${prefix}LP_Module_Started`;
export const LP_MODULE_COMPLETED = `${prefix}LP_Module_Completed`;
export const LP_CONTENT_ACCESSED = `${prefix}LP_Content_Accessed`;
export const LP_CONTENT_STARTED = `${prefix}LP_Content_Started`;
export const LP_CONTENT_COMPLETED = `${prefix}LP_Content_Completed`;
export const LP_ASSESSMENT_DETAILS_CHECKED = `${prefix}LP_LandingPage_Assessment_checked`;
export const LP_PITCH_RECORD_CLICKED = `${prefix}LP_Content_Assessment_PitchRecordClick`
export const LP_TIPS_OPENED = `${prefix}LP_Content_Assessment_Pitchttopen`;
export const ASSESSMENT_SUBMITTED = `${prefix}LP_Content_Assessment_Submitted`;
export const QNA_LOADED = `${prefix}LP_Question_PageLoaded`;
export const LP_COMPLETED = `${prefix}Learning_Path_Completed`;
export const LP_ASSESSMENT_LOADED =`${prefix}LP_Assessment_Loaded`;
export const LP_ASSESSMENT_ANSWERED = `${prefix}LP_Assessment_answered`;
export const LP_ASSESSMENT_COMPLETED = `${prefix}LP_Assessment_completed`;
export const LP_ASSIGNED = `${prefix}LP_Assigned`;
export const MODULE_DRAWER_CTA_CLICKED = `${prefix}Module_Drawer_Cta_Clicked`;
export const LMS_CONTENT_SEEK_CLICKED = `${prefix}Content_Seek`;
export const LP_Start_Assessment_Clicked = `${prefix}LP_Start_Assessment_Clicked`
export const LP_Assessment_Back_Clicked = `${prefix}LP_Assessment_Back_Clicked`
export const LP_Assessment_Close_Clicked = `${prefix}LP_Assessment_Close_Clicked`
export const LP_CERTIFICATE_SHARE_CLICKED = `${prefix}LP_Certificate_Share_Clicked`

export const NEXT_CONTENT_AUTOSTART = `${prefix}Next_Content_Auto_Start`;
export const NEXT_CONTENT_CANCELLED = `${prefix}Next_Content_Cancelled`;

export const LMS_WEBINAR_SHARE_WHATSAPP_CLICKED = `${prefix}Webinar_share_whatsapp_clicked`;
export const MA_Logo_Clicked = `${prefix}MA_Logo_Clicked`

export const LMS_WHATSAPP_POPUP_APPEARED = `${prefix}Whatsapp_Popup_Appeared`;
export const LMS_WHATSAPP_POPUP_CLOSED = `${prefix}Whatsapp_Popup_Closed`;
export const LMS_WHATSAPP_POPUP_TOPIC_CLICKED = `${prefix}Whatsapp_Popup_Topic_Clicked`;

export const LESSON_VIDEO_START= `${prefix}Lesson_Video_Start`
export const LESSON_VIDEO_PAUSED= `${prefix}Lesson_Video_Paused`
export const LESSON_VIDEO_COMPLETE= `${prefix}Lesson_Video_Complete`
export const LESSON_VIDEO_LEFT_HALFWAY= `${prefix}Lesson_Video_Left_Halfway`

export const LP_FOLLOWUP_LP_SELECTED = `${prefix}LP_Followup_LP_Selected`
export const LP_FOLLOWUP_START_SELECTION_CLICKED = `${prefix}LP_Followup_Start_Selection_Clicked`
export const LP_FOLLOWUP_CANCEL_CLICKED = `${prefix}LP_Followup_Cancel_Clicked`
export const LP_CERTIFICATION_PAGE_LOADED = `${prefix}LP_Certification_Page_Loaded`
export const LP_CERTIFICATE_DOWNLOAD_CLICKED = `${prefix}LP_Certificate_Download_clicked`
export const LP_FOLLOWUP_CONFIRM_CLICKED = `${prefix}LP_Followup_Confirm_Clicked`

export const CONTENT_NEXT_CTA_CLICKED = `${prefix}Content_Next_Cta_Clicked`;

export const ACTION_SECTION_ITEM_CLICKED =  `${prefix}Actionable_Section_Clicked`

export const EXPLORE_MORE_CTA_CLICKED = `${prefix}Explore_more_CTA_clicked`;

export const POSPCOURSE_POPUP_APPEARED = `${prefix}Pospcourse_Completion_Popup_Appeared`
export const POSPCOURSE_POPUP_CLOSED = `${prefix}Pospcourse_Completion_Popup_Closed`

export const POSPEXAM_COMPLETION_LP_VIDEO_CLICKED = `${prefix}Pospexam_completion_lp_video_clicked`;

// Hybrid Learning Path Mixpanel
export const LMS_LP_SELECTION_PAGE_LOADED = `${prefix}LP_Selection_Page_Loaded`;
export const LMS_LP_SELECTED = `${prefix}LP_Selected`;
export const LMS_LP_SELECTION_CONFIRM_CLICKED = `${prefix}LP_Selection_Confirm_Clicked`;
export const LMS_LP_SELECTION_CANCEL_CLICKED = `${prefix}LP_Selection_Cancel_Clicked`;
export const LMS_LP_SELECTION_ASSESSMENT_CTA_CLICKED = `${prefix}LP_Selection_Assessment_CTA_Clicked`;
export const LMS_LP_NAVIGATE_TO_LISTING_CTA_CLICKED = `${prefix}LP_Navigate_To_Listing_CTA_Clicked`;
export const LMS_LP_SEE_ALL_CERTIFICATE_CTA_CLICKED = `${prefix}LP_See_all_Certificate_CTA_Clicked`;


export const REEL_SCROLLED = `${prefix}Reel_Scrolled`;
export const REEL_INTERACTION = `${prefix}Reel_Interaction`;
export const REEL_REPLAYED = `${prefix}Reel_Replayed`;
export const REEL_FEED_ENTRY = `${prefix}Reel_Feed_Entry`;
export const REEL_FEED_EXIT = `${prefix}Reel_Feed_Exit`;
export const REEL_FEED_COMPLETE = `${prefix}Reel_Feed_Completed`;
export const REEL_FINAL_CTA_CLICKED = `${prefix}Reel_Completion_CTA_Clicked`;
export const DYNAMIC_DEEP_LINK_RECOMMENDATION_REDIRECT = `${prefix}Dynamic_Deep_Link_Recommendation_Redirect`;
export const DYNAMIC_DEEP_LINK_RECOMMENDATION_REDIRECT_FAILED = `${prefix}Dynamic_Deep_Link_Recommendation_Redirect_Failed`;

export const REEL_CTA_CLICKED = `${prefix}Reel_CTA_Clicked`;

export const LMS_PREMIUM_BUY_NOW_CLICKED = `${prefix}Premium_Buy_Now_Clicked`;
export const LMS_PREMIUM_START_LEARNING_CTA_CLICKED = `${prefix}Premium_Start_Learning_CTA_Clicked`;
export const LMS_PREMIUM_TRANSACTION_FINAL_SCREEN = `${prefix}Premium_Transaction_Final_Screen`;
export const LMS_PREMIUM_RAZORPAY_MODAL_LOAD_FAILED = `${prefix}Premium_Razorpay_Modal_Load_Failed`;
export const LMS_PREMIUM_RETRY_PAYMENT_CLICKED = `${prefix}Premium_Retry_Payment_Clicked`;
export const LMS_PREMIUM_COURSE_DETAILS_LOADED = `${prefix}Premium_Course_Details_Loaded`
export const LMS_USER_TRANSACTION_STATUS = `${prefix}User_Transaction_Status`;
export const LMS_PURCHASED_VIEW_MORE_CLICKED = `${prefix}Purchased_View_More_clicked`;
export const LMS_PREMIUM_LISTING_PAGE_LOADED = `${prefix}Premium_Listing_Page_Loaded`;
export const LMS_PURCHASED_COURSES_LISTING_PAGE_LOADED = `${prefix}Purchased_Courses_Listing_Page_Loaded`;
export const LMS_PREMIUM_PAYMENT_MODAL_CLOSED = `${prefix}Premium_Payment_Modal_Closed`;
export const LMS_PREMIUM_PWAP_MODAL_CTA_CLICKED = `${prefix}Premium_Pwap_Modal_Cta_Clicked`;
export const LMS_PREMIUM_PAYMENT_INITIATED = `${prefix}Premium_Payment_Initiated`;
export const LMS_FLASHCARD_EXIT = `${prefix}Flashcard_Exit`; 
export const NOT_FOUND_PAGE_LOADED = `${prefix}404_Page_Loaded`;

export const QUIZ_ANSWER_SELECTED = `${prefix}Quiz_Answer_Selected`;
export const QUIZ_EXPLANATION_POPUP_CLOSED = `${prefix}Quiz_Explanation_Popup_Closed`;
export const QUIZ_EXPLANATION_POPUP_CONTINUE_CLICKED = `${prefix}Quiz_Explanation_Popup_Continue_Clicked`;
export const QUIZ_NAVIGATED = `${prefix}Quiz_Navigated`;
export const QUIZ_LOADED = `${prefix}Quiz_Loaded`;
export const QUIZ_COMPLETED = `${prefix}Quiz_Completed`;
export const QUIZ_EXITED = `${prefix}Quiz_Exited`;

export const ALL_CATEGORY_PAGE_LOADED = `${prefix}All_Category_Page_Loaded`;
export const CATEGORY_CLICKED = `${prefix}Category_Clicked`;
export const CATEGORY_PAGE_LOADED = `${prefix}Category_Page_Loaded`;
export const PAGE_SCROLLED = `${prefix}Page_Scrolled`;
export const PAGE_LOADED = `${prefix}Page_Loaded`;

export const ACADEMY_LOADED = `Academy_Loaded`;
export const ACTIONABLE_EXPAND_CLICKED = `${prefix}Actionable_Expand_Clicked`;
export const SOCIAL_PROOF_CLICKED = `${prefix}Social_Proof_Clicked`;
export const FEATURE_TAB_CLICKED = `${prefix}Feature_Tab_Clicked`;
export const BANNER_INTERACTION = `${prefix}Banner_Interaction`;
export const BACK_TO_HOME_CLICKED = `${prefix}Back_To_Home_Clicked`;

export const PREMIUM_WEBINAR_BUY_NOW_CLICKED = `${prefix}Premium_Webinar_Buy_Now_Clicked`;

export const LMS_WEBINAR_LISTING_PAGE_ACCESSED = `${prefix}Webinar_Listing_Page_Accessed`;
export const LMS_WEBINAR_TILE_CLICKED = `${prefix}Webinar_Tile_Clicked`;
export const LMS_FILTERS_APPLIED = `${prefix}LMS_Filters_Applied`;
export const LMS_RECORDED_WEBINAR_CLICKED = `${prefix}Recorded_Webinar_Clicked`;

export const LMS_SWITCH_LP_CLICKED = `${prefix}Switch_LP_Clicked`;
export const LMS_SWITCH_LP_REASON_POPUP_LOADEED = `${prefix}Switch_LP_Reason_Popup_Loaded`;
export const LMS_SWITCH_LP_REASON_OPTION_SELECETED = `${prefix}Switch_LP_Reason_Option_Selected`;
export const LMS_SWITCH_LP_REASON_CTA_CLICKED = `${prefix}Switch_LP_Reason_CTA_Clicked`;
export const LMS_SWITCH_LP_SELECTION_PAGE_LOADED = `${prefix}Switch_LP_Selection_Page_Loaded`;
export const LMS_SWITCH_LP_SELECTED = `${prefix}Switch_LP_Selected`;
export const LMS_LP_LANDINGPAGE_CTA_CLICKED = `${prefix}LP_LandingPage_CTA_Clicked`;

export const CERTIFICATE_PAGE_TAB_CLICKED = `${prefix}Certificate_Page_Tab_Clicked`;
export const POSP_CERTIFICATE_DOWNLOAD_CLICKED = `${prefix}POSP_Certificate_Download_Clicked`;
export const POSP_AGREEMENT_ACCEPTED = `${prefix}POSP_Agreement_Accepted`;

export const QUIZ_CENTER_ASSESSMENT_LOADED = `${prefix}Quiz_Center_Assessment_Loaded`;
export const QUIZ_CENTER_ASSESSMENT_COMPLETED = `${prefix}Quiz_Center_Assessment_Completed`;
